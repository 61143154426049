import logo from './logo.svg';
import './App.css';
import Cards from './Components/Cards';

function App() {
  return (
    <div>
     <Cards/>
    </div>
  );
}

export default App;
