import React from 'react';
// Import images from the src folder
import Image1 from '../Assets/1.png';
import Image2 from '../Assets/2.png';
import Image3 from '../Assets/3.png';
import Image4 from '../Assets/4.png';
import Image5 from '../Assets/5.png';
import Image6 from '../Assets/6.png';

function Cards() {
  // Array containing details for each card, including imported images and links
  const cards = [
    { id: 1, link: "https://www.chakragroup.in/", image: Image1 },
    { id: 2, link: "https://www.chakrainfotainment.com/", image: Image2 },
    { id: 3, link: "https://www.algoworks.co.in/", image: Image3 },
    { id: 4, link: "https://www.joybharath.com/", image: Image4 },
    { id: 5, link: "https://zingreel.in/vendorform", image: Image5 },
    { id: 6, link: "https://www.pvrbanquethall.com/", image: Image6 },
  ];

  return (
    <div className="bg-gradient-to-r from-gray-400 to-gray-800 min-h-screen w-full">
      <div className="container mx-auto py-10 px-4">
        <h1 className="text-white text-2xl sm:text-3xl text-center font-serif mb-8">
          Chakra Group Centralized Dashboard
        </h1>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {cards.map((card) => (
            <a
              key={card.id}
              href={card.link}
              target="_blank"
              rel="noopener noreferrer"
              className="relative group block overflow-hidden rounded-lg shadow-lg transition-transform transform hover:scale-105"
            >
              <img
                src={card.image}
                alt={`Link to ${card.link}`}
                className="w-full h-72 object-cover"
              />
              <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <p className="text-white text-lg font-semibold">{card.title}</p>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Cards;
